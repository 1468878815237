import debounce from 'lodash/debounce';
import helpers from '../util/helpers';
import * as ratings from './ratings';
import { parseResults, primeCacheForNextPage } from './search-helpers';
import { mq } from '../assets/helpers';
import { paginationHelper } from './searchSEOHelper';
import { addGlobalProductTileEvents, calculateSwatchesNotShown } from '../components/productTile';

const wishlistsEnabled = !!window.patagonia?.clientDataModel?.wishlist;
let tealium = null;

let canonicalURL = new URL(window.location);
let page = canonicalURL.searchParams.get('page')
    ? parseInt(canonicalURL.searchParams.get('page'), 10)
    : 1;

let loadType = 'initial';
let ajaxUrl = null;

function getStateObj() {
    return {
        url: canonicalURL.toString(),
        type: loadType,
        ajaxUrl: ajaxUrl ? ajaxUrl.toString() : null,
    };
}

function pushHistory(opts = {}) {
    try {
        window.history.pushState(
            getStateObj(),
            opts.newPageTitle ? opts.newPageTitle : null,
            opts.historyUrl ? opts.historyUrl : canonicalURL
        );
    } catch (error) {
        console.warn(error);
    }
}

function replaceHistory(opts = {}) {
    try {
        window.history.replaceState(
            getStateObj(),
            null,
            opts.historyUrl ? opts.historyUrl : canonicalURL
        );
    } catch (error) {
        console.warn(error);
    }
}

function checkForMarketingContent() {
    const lazyProductGrid = document.querySelector('.ll-search-results');
    if (lazyProductGrid) {
        if (!lazyProductGrid.classList.contains('lazyloaded-ajax')) {
            return;
        }
    }
    const hasMarketingContent =
        $(
            '.cat-module-container .grid-product-tiles, .lazyload--product-slider, .lazyload--slider, .storytelling, .lazyload--story-scroller'
        ).length > 0;
    $('.search-results--no-decorator-title').toggleClass('d-none', !hasMarketingContent);
}

function checkForFilters() {
    const filtersFound = document.querySelector('.shopping-refinement__attrs') !== null;
    $('.needs-filters:not(.show)').toggleClass('show', filtersFound);
}

function parseForNewCanonical(response) {
    const $results = $(`<div>${response}</div>`);
    if ($results.find('.search-results').length > 0) {
        const canonical = $results.find('.search-results').data('canonical-url');
        if (canonical && canonical.length > 1) {
            canonicalURL = new URL(canonical);
        }
    }
}

/**
 * Initializes Infinite Scroll
 *
 * @description - Checks if infinite scroll is enabled and allowed, then sets observer if true.
 * Site Preference Infinite Scroll Setting - Disable = 0, Enable = 1, Desktop Only = 2, Mobile Only = 3
 */
function initInfiniteScroll() {
    const loadMoreBtn = document.querySelector('.show-more .btn');
    const infiniteScrollSetting = parseInt(loadMoreBtn?.dataset?.infiniteScrollSetting, 10);
    const isDesktop = mq('large');
    const infiniteScrollHide =
        !infiniteScrollSetting ||
        infiniteScrollSetting === 0 ||
        (isDesktop && infiniteScrollSetting === 3) ||
        (!isDesktop && infiniteScrollSetting === 2);

    if (
        document.body.classList.contains('infinite-scroll-tracked') ||
        !loadMoreBtn ||
        infiniteScrollHide
    ) {
        return;
    }

    document.body.classList.add('infinite-scroll-tracked');

    if (loadMoreBtn) {
        const intersectionCallback = (entries, observer) => {
            // Reset on resize
            window.addEventListener(
                'resize',
                () => {
                    observer.disconnect();
                    document.body.classList.remove('infinite-scroll-tracked');
                },
                { once: true }
            );

            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    observer.disconnect();
                    document.body.classList.remove('infinite-scroll-tracked');
                    loadMoreBtn.click();
                }
            });
        };

        const options = {
            rootMargin: '100%',
        };

        const observer = new IntersectionObserver(intersectionCallback, options);
        observer.observe(loadMoreBtn);
    }
}

function fillLastRow() {
    const grids = document.querySelectorAll('.product-grid');
    grids.forEach((grid) => {
        [...grid.querySelectorAll('.last-in-full-grid')].forEach((product) => {
            product.classList.remove('last-in-full-grid');
        });
        requestAnimationFrame(() => {
            if (grid.querySelector('.show-more')) {
                const products = [...grid.querySelectorAll('.product-tile__wrapper')].filter(
                    (tile) => tile.querySelector('.product')
                );
                let foundLastFull = false;
                let index = products.length;
                let product;
                do {
                    index -= 1;
                    product = products[index];
                    if (
                        product &&
                        Math.abs(product.offsetLeft + product.offsetWidth) >= grid.offsetWidth * 0.9
                    ) {
                        foundLastFull = true;
                        product.classList.add('last-in-full-grid');
                    }
                } while (!foundLastFull && index > 0);
            }
        });
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    const showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success(response) {
            $target.append(response);
            $('.js-content-result-count').text($('.content-search-results__result').length);
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    const $tempDom = $('<div>').append($(response));
    const sortData = $tempDom.find('.grid-footer').data('sort-options');
    const sortOptions = sortData.options;

    if (sortOptions) {
        if (!sortData.usingDefaultSortRule) {
            $('.shopping-sort-header__sort-order-menu select').removeClass('default');
            $('.shopping-sort-header__sort-order-menu label').removeClass('d-none');
            $('.shopping-sort-header__sort-order-menu option[value="default"]').remove();
        }

        sortOptions.forEach((option) => {
            $(`option.${option.id}`).val(option.url);
        });
    }
}

function ajaxNewContent(e, url, opts = {}) {
    const $searchResults = $('.search-results');
    const elementsToDisable = $('.filter-refine__mega-refinements, .col.filter-refine');

    if (opts.type) {
        loadType = opts.type;

        if (opts.type !== 'page') {
            $searchResults.spinner().start();
            elementsToDisable.addClass('disabled-element');
        } else if (opts.type === 'page') {
            $(e.target).addClass('btn-loading').attr('disabled', '');
        }
    }

    ajaxUrl = new URL(url);
    ajaxUrl.searchParams.append('ajax', 1);
    if (opts.type !== 'page') {
        ajaxUrl.searchParams.delete('page');
    }
    $.ajax({
        url: ajaxUrl,
        data: opts.data,
        method: 'GET',
        success(response) {
            parseForNewCanonical(response);

            if (opts.type === 'sort') {
                $('.product-grid').empty().html(response);
                updateSortOptions(response);
            }

            if (opts.type === 'page') {
                page += 1;
            }
            const currentPage = page;
            const $results = $(`<div>${response}</div>`);

            if (opts.type === 'page') {
                $('.grid-footer').replaceWith($results[0].innerHTML);
            }

            updateSortOptions(response);
            if (window.history) {
                canonicalURL.searchParams.set('page', currentPage);
            }

            if (['filter', 'initial', 'cat-tabs'].includes(opts.type)) {
                parseResults(response, opts);

                if (opts.type === 'cat-tabs') {
                    if (opts.newPageTitle) {
                        document.title = opts.newPageTitle;
                    }
                }
            }

            if (!$('.show-more button').is(':visible')) {
                $('.product-grid').removeClass('load-more-present');
                $('a[rel="next"]').remove();
            } else {
                $('.product-grid').addClass('load-more-present');
            }

            if (opts.success && typeof opts.success === 'function') {
                opts.success(response);
            }

            if (opts.type !== 'page') {
                canonicalURL.searchParams.delete('page');
                $searchResults.spinner().stop();
            } else if (opts.type === 'page') {
                $(e.target).addClass('btn-loading').attr('disabled', '');
            }

            elementsToDisable.removeClass('disabled-element');

            if (!opts.skipHistoryUpdate) {
                if (opts.type === 'page') {
                    replaceHistory(opts);
                } else {
                    pushHistory(opts);
                }
            }
            // SEO Pagination for Content pages
            paginationHelper();

            // Calculate view more swatches number
            calculateSwatchesNotShown();

            // Update wishlist buttons
            if (wishlistsEnabled) {
                document.dispatchEvent(new Event('updateWishlistButtonStates'));
            }

            // Add event listener to fire Viewed Badge for new product tiles
            document.dispatchEvent(new CustomEvent('DetectNewFirstInteractions'));

            // Update login redirect URL
            const $gridFooter = $('.grid-footer');
            if ($gridFooter.attr('data-login-url') && window.patagonia?.clientDataModel?.loginUrl) {
                window.patagonia.clientDataModel.loginUrl = $gridFooter.attr('data-login-url');
            }

            addGlobalProductTileEvents();
            fillLastRow();

            document.body.classList.remove('infinite-scroll-tracked');
            initInfiniteScroll();

            ratings.loadRatingsForNewTiles();

            document.dispatchEvent(new CustomEvent('UpdateSelectCompareProductTiles'));
        },
        error(error) {
            if (opts.error && typeof opts.error === 'function') {
                opts.error(error);
            }
            $searchResults.spinner().stop();
            elementsToDisable.removeClass('disabled-element');
        },
    });
}

export default {
    contentHeaderTab() {
        require('../components/contentHeaderTab');
    },
    sort() {
        // Handle sort order menu selection
        function handleSortOrderRefinement(e, ajaxURL, historyUrl) {
            // store the last refinement URL
            $('.shopping-tool__cta-anchor--apply').attr('data-last-url', historyUrl);

            ajaxNewContent(e, ajaxURL, {
                type: 'sort',
                data: { selectedUrl: ajaxURL },
                historyUrl,
                success(response) {
                    if (canonicalURL && !e.isTrigger && tealium) {
                        tealium.collectRefinementData(
                            $(response).toArray(),
                            canonicalURL.search || ''
                        );
                    }
                },
            });
        }
        $('.page').on('change', '.sort-order-refinement', function (e) {
            const $selectedOption = $(this).find('option:selected');
            const $sortRefinements = $('.shopping-refinements__refinement--sort ul li');
            const $sortTerm = $('.shopping-refinement__header__sort-term');
            const selectedID = $selectedOption.attr('data-optionid');
            const historyUrl = $selectedOption.attr('data-history');
            ajaxUrl = $selectedOption.val();

            if (ajaxUrl) {
                $sortTerm.text($selectedOption.text());
                $sortRefinements.each(function () {
                    if ($(this).attr('data-optionid') === selectedID) {
                        $(this).addClass('active');
                        $(this).find('a').addClass('active');
                    } else {
                        $(this).removeClass('active');
                        $(this).find('a').removeClass('active');
                    }
                });

                handleSortOrderRefinement(e, ajaxUrl, historyUrl);
            }
        });

        $('.page').on('click', '.shopping-refinement__attr--sort-option', function (e) {
            e.preventDefault();

            const historyUrl = $(this).attr('data-history');
            const selectedID = $(this).closest('li').attr('data-optionid');
            const $headerSortOrder = $('.sort-order-refinement');
            const updatedSortOrderVal = $headerSortOrder
                .find(`option[data-optionid="${selectedID}"]`)
                .val();
            ajaxUrl = $(this).attr('href');

            $headerSortOrder.val(updatedSortOrderVal);

            document
                .querySelectorAll('.shopping-refinement__attr--sort-option')
                .forEach((sortOption) => {
                    sortOption.setAttribute('aria-checked', 'false');
                });

            this.setAttribute('aria-checked', 'true');

            handleSortOrderRefinement(e, ajaxUrl, historyUrl);
        });
    },

    showMore() {
        // Show more products
        $('.page').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            const showMoreUrl = $(this).data('url');

            e.preventDefault();

            $(this).trigger('search:showMore', e);
            ajaxNewContent(e, showMoreUrl, { type: 'page', data: { selectedUrl: showMoreUrl } });
        });
    },

    applyFilter() {
        // Handle refinement value selection and reset click
        const evt = document.createEvent('Event');

        $('.page').on(
            'click',
            `a.shopping-refinement__attr,
            .shopping-refinement__attr a,
            .shopping-refinements__cta-clear-all,
            .filter-refine-pills a,
            .filter-refine__mega-refinements a`,
            function (e) {
                if (e.currentTarget.classList.contains('shopping-refinement__attr--sort-option')) {
                    return;
                }

                const category = $(e.currentTarget).closest('.shopping-refinements__refinement');

                if (category && category.hasClass('shopping-refinements__refinement--category')) {
                    return;
                }

                const currentUrl = window.location.href;
                e.preventDefault();

                $(e.currentTarget).closest('.shopping-refinements').attr('data-change', 'true');

                let url = e.currentTarget.href;

                // if this is a store filter click create a fake url if the refinement url doesn't exist
                if (url.indexOf('null') > -1 && $(this).attr('class').indexOf('--pickup') > -1) {
                    // get the next refinement number
                    const nextNum = helpers.getNextPrefNum();

                    // create a fake url - this will lead to no results & is intentional
                    url = helpers.appendParamToURL(
                        currentUrl,
                        `prefn${nextNum}`,
                        'availableStores'
                    );
                    url = helpers.appendParamToURL(
                        url,
                        `prefv${nextNum}`,
                        $(this).attr('data-store-id')
                    );
                }

                // Grab the current sort
                const lastSearch = $('.shopping-tool__cta-anchor--apply').attr('data-last-url');
                const sort = helpers.getURLParameter('srule', lastSearch || url);
                if (sort) {
                    url = helpers.updateURLParameter(url, 'srule', sort);
                }

                // store the last refinement URL
                $('.shopping-tool__cta-anchor--apply').attr('data-last-url', url);

                // Notify External Listeners that Apply Filter Started
                evt.initEvent('ApplyFilter-Start', true, true);
                document.dispatchEvent(evt);

                $(this).trigger('search:filter', e);

                ajaxNewContent(e, url, {
                    type: 'filter',
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: url,
                    },
                    success(response) {
                        if (canonicalURL && !e.isTrigger && tealium) {
                            tealium.collectRefinementData(
                                $(response).toArray(),
                                canonicalURL.search || ''
                            );
                        }
                        // Notify External Listeners that Apply Filter Stopped
                        evt.initEvent('ApplyFilter-Stop', true, true);
                        document.dispatchEvent(evt);
                    },
                    error() {
                        // Notify External Listeners that Apply Filter Stopped
                        evt.initEvent('ApplyFilter-Stop', true, true);
                        document.dispatchEvent(evt);
                    },
                });
            }
        );
    },

    showContentTab() {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.content-search-results').on('click', '.show-more-content button', function () {
            getContent($(this), $('.content-search-results'));
            $('.show-more-content').remove();
        });
    },

    clearRefinements() {
        $('.page').on('click', '.refinements-clear-all', () => {
            $('.filter-refine-pills__clear-all').click();
        });
    },

    lazyLoadGrid() {
        const lazyProductGrid = document.querySelector('.ll-search-results');

        if (lazyProductGrid) {
            lazyProductGrid.addEventListener(
                'AjaxContentLoaded',
                () => {
                    const resultCount = Number($('.search-results').data('searchResultsCount'));
                    if (resultCount === 0 && $('.content-search-results__result').length > 0) {
                        const PLPHero = document.querySelector('.category-hero');
                        if (PLPHero) {
                            PLPHero.classList.add('category-hero--no-results');
                        }
                    }

                    // Update wishlist buttons
                    if (wishlistsEnabled) {
                        document.dispatchEvent(new Event('updateWishlistButtonStates'));
                    }

                    if (tealium) {
                        window.utag_data.search_results = resultCount.toString();
                        tealium.collect('search', window.utag_data);
                    }
                },
                { once: true }
            );
        }
    },
    initDoc() {
        $('document').ready(() => {
            // SEO Pagination for Content pages
            paginationHelper();

            document.addEventListener('ReloadShoppingTool-Refinements', checkForFilters);
            document.addEventListener('ReloadShoppingTool-Refinements', checkForMarketingContent);
            checkForFilters();
            checkForMarketingContent();
            window.addEventListener(
                'scrollUpdate',
                () => {
                    primeCacheForNextPage();
                },
                { once: true }
            );

            fillLastRow();

            window.addEventListener('resize', debounce(fillLastRow, 150));
            document.addEventListener('ProductList-MarketingAdded', debounce(fillLastRow, 150));

            initInfiniteScroll();
            window.addEventListener('resize', debounce(initInfiniteScroll, 150));
            document.addEventListener('DetectNewFirstInteractions', initInfiniteScroll);

            document.querySelector('.content-tab__search-form')?.addEventListener('submit', (e) => {
                if (typeof window.BrTrk !== 'undefined') {
                    window.BrTrk.getTracker().logEvent(
                        'suggest',
                        'submit',
                        {
                            q: e.target.querySelector('[name="q"]').value,
                            catalogs: [
                                { name: window?.patagonia?.clientDataModel?.bloomreach?.catalog },
                            ],
                        },
                        {},
                        true
                    );
                }
            });

            try {
                window.history.replaceState(getStateObj(), null, canonicalURL);
                window.addEventListener('popstate', (event) => {
                    const url = event.state.ajaxUrl || event.state.url;
                    if (url) {
                        ajaxNewContent(event, url, {
                            type: event.state.type,
                            skipHistoryUpdate: true,
                            data: { selectedUrl: url },
                        });
                    }
                });
            } catch (error) {
                console.warn(error);
            }
        });
    },
    loadTealium() {
        import('../app/tealium/tealium.js').then(({ default: tealiumLib }) => {
            tealium = tealiumLib;
        });
    },
    ratings() {
        ratings.init();
    },
};
